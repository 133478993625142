html, body {
  height: 100%;
  min-height: 100%;
}

body, button {
  margin: 0;
  font-family: 'Comfortaa', cursive;
}

input {
  font-family: 'Comfortaa', cursive;
}

button {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
}

.app-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.app-background img {
  height: 220px;
  position: fixed;
  /* opacity: 0.2; */
}

.bg-image-top-left {
  left: -50px;
  top: 20px;
  transform: rotate(-20deg);
}

.bg-image-top-right {
  right: -30px;
  top: 0px;
  transform: rotate(-40deg);
}

.bg-image-bottom-left {
  left: -50px;
  bottom: 0;
  transform: rotate(20deg);
}

.bg-image-bottom-right {
  right: -30px;
  bottom: -20px;
  transform: rotate(-20deg);
}

.bg-image-1 {
  left: 10%;
  top: 24%;
  transform: rotate(-20deg);
}

.bg-image-2 {
  left: 26%;
  top: 28%;
  transform: rotate(-20deg);
}

.bg-image-3 {
  left: 66%;
  top: 25%;
  transform: rotate(-20deg);
}

.bg-image-4 {
  left: 79%;
  top: 37%;
  transform: rotate(-20deg);
}

.bg-image-5 {
  left: 4%;
  top: 42%;
  transform: rotate(-20deg);
}

.bg-image-6 {
  left: 17%;
  top: 45%;
  transform: rotate(-20deg);
}

.bg-image-7 {
  left: 70%;
  top: 45%;
  transform: rotate(-20deg);
}

.bg-image-8 {
  left: 90%;
  top: 44%;
  transform: rotate(-20deg);
}

.bg-image-9 {
  left: 7%;
  top: 63%;
  transform: rotate(-20deg);
}

.bg-image-10 {
  left: 30%;
  top: 50%;
  transform: rotate(-20deg);
}

.bg-image-11 {
  left: 66%;
  top: 64%;
  transform: rotate(-20deg);
}

.bg-image-12 {
  left: 93%;
  top: 66%;
  transform: rotate(-20deg);
}

.bg-image-13 {
  left: 12%;
  top: 80%;
  transform: rotate(-20deg);
}

.bg-image-14 {
  left: 29%;
  top: 77%;
  transform: rotate(-20deg);
}

.bg-image-15 {
  left: 73%;
  top: 78%;
  transform: rotate(-20deg);
}

.bg-image-16 {
  left: 85%;
  top: 80%;
  transform: rotate(-20deg);
}

.bg-image-17 {
  left: 21%;
  top: 66%;
  transform: rotate(-20deg);
}

.bg-image-18 {
  left: 79%;
  top: 59%;
  transform: rotate(-20deg);
}

.bg-image-19 {
  left: 87%;
  top: 24%;
  transform: rotate(-20deg);
}

.lang-img {
  cursor: pointer;
  height: 36px;
  margin: 0 12px;
}

/* START: toggle button styles */

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch img {
  position: absolute;
  display: inline-block;
  height: 18px;
  bottom: 4px;
}

.switch .light-mode-img {
  left: 4px;
}

.switch .dark-mode-img {
  left: 30px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  z-index: 99999;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #000000d4;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000d4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  border-radius: 50%;
}

/* END: toggle button styles */

@media only screen and (max-width: 600px) {

  .app-background img {
    height: 150px;
  }
}

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
