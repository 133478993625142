.wizard-container {
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.buttons-spacer-top {
  height: 12px;
  /* height: 52px; */
}

.buttons-spacer-bottom {
  height: 0;
}

.selection-question {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  padding: 10px 20px;
}

.wizard-step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  /* padding: 20px 0 0 0; */
}

.ingredients-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 10px;
}

.ingredients-container > div {
  width: 120px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px 20px;
  margin: 5px;
  /*border: 1px solid black;*/
  border-radius: 10px;
  cursor: pointer;
  transition: transform .2s; /* Animation */
}

.ingredients-container img {
  height: 120px;
}

.ingredients-container > div:hover {
  /*background-color: lightgrey;*/
  transform: scale(1.1);
}

.more-main-container {
  padding-bottom: 10px;
}

.more-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
}

.more-options-container {
  overflow: hidden;
  text-align: center;
}

.more-options-container .ingredients-container {
  max-width: 800px;
}

.more-options-container .ingredients-container > div {
  padding: 0;
  width: 90px;
}

.more-options-container .option-name {
  padding-top: 10px;
  font-size: 12px;
}

.more-options-container img {
  height: 40px;
}

.more-options-closed {
  max-height: 0;
}

.search-option-input {
  font-size: 16px;
  padding: 6px;
  margin: 8px 0 18px 0;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 400px;
}

.option-name {
  font-size: 20px;
  font-weight: bold;
  padding-top: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.wizard-step-bottom-buttons-container {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.wizard-step-bottom-buttons-container button {
  margin: 5px;
  padding: 10px;
  width: 150px;
}

.back-button {
  font-weight: bold;
  background-color: #c1f3ff;
  color: black;
  border: 2px solid #00CEFF;
}

.back-button:hover {
  background-color: #00CEFF;
  color: white;
}

.skip-button {
  font-weight: bold;
  background-color: #e1e1e1;
  color: black;
  border: 2px solid #5D6C6D;
}

.skip-button:hover {
  background-color: #5D6C6D;
  color: white;
}

.choose-option-for-me {
  font-weight: bold;
  background-color: #e3c0e7;
  color: black; 
  border: 2px solid #CA54D8;
}

.choose-option-for-me:hover {
  background-color: #CA54D8;
  color: white;
}

.selections-main-container {
  width: 100%;
  /* height: 110px; */
  /* text-align: center; */
  /* padding-top: 10px; */
  padding-bottom: 10px;
}

.selections-header {
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

.selections-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.selection-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 2px 0px 2px;
}

.selection-category {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.selection-container {
  display: flex;
  flex-direction: column;
  width: 60px;
  text-align: center;
  border: 2px solid #50FF9A;
  border-radius: 10px;
  padding: 5px;
  margin-top: 2px;
  /* margin: 2px 5px 0px 5px; */
  font-size: 14px;
  background-color: white;
  cursor: pointer;
}

.dark-mode .selection-container {
  background-color: #292929;
}

.selection-container img {
  height: 30px;
  padding: 0 0 5px 0;
}

.selection-skipped {
  /*
  opacity: 0.5;
  border: 2px solid #5D6C6D;
  background-color: lightgray;
  */
}

.selection-skipped:hover {
  /* background-color: lightgray !important; */
}

.selection-skipped img {
  
}

.selection-container:hover {
  background-color: #d9fae7;
  color: black;
}

.selection-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
  font-size: 10px;
}

.selection-edit-main-container {
  /*border-top: 2px solid white;*/
  position: absolute;
  left: 6px;
  z-index: 1;
}

.selection-edit-container {
  /*border: 2px solid #5D6C6D;*/
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /*padding: 5px;*/
  /*width: 100px;*/
  /*height: 30px;*/
  z-index: 1;
}

.selection-edit-container img {
  height: 22px;
  padding: 3px;
  margin: 2px 2px 0 2px;
  border: 2px solid #8e8e8e;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.selection-edit-container img:nth-child(1):hover {
  background-color: #fbffc2;
}

.selection-edit-container img:nth-child(2):hover {
  background-color: #dadada;
}

.start-again-button {
  min-width: 140px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 10px;
  color: #273235;
  background-color: #fbffc2;
  border-radius: 58px;
  font-weight: bold;
  border: 2px solid #F5FF5E;
}

.start-again-button:hover {
  background-color: #F5FF5E;
  color: #283235;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #7d7d7d;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.loader-container {
  text-align: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 60px;
}

.loader-container > div {
  padding: 10px 0;
}

.loader-container img {
  height: 200px;
}

.recipe-summary-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin: 10px;
}

.view-full-recipe-button {
  font-weight: bold;
  background-color: #d9fae7;
  color: black; 
  border: 2px solid #50FF9A;
  padding: 15px;
}

.view-full-recipe-button:hover {
  background-color: #50FF9A;
  color: white;
}

.try-again-container button {
  font-weight: bold;
  padding: 15px;
}

.try-again-container button {
  background-color: #e3c0e7;
  border: 2px solid #CA54D8;
}

.try-again-container button:hover {
  background-color: #CA54D8;
  color: white;
}

.recipe-details-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
 
.recipe-details-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  /* padding: 10px; */
  border-radius: 10px;
  border: 2px solid #1efc89;
  background-color: white;
  margin: 10px 10px 10px 10px;
}

.dark-mode .recipe-details-main-container {
  background-color: #292929;
}

.recipe-summary-container  {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #1efc89;
  /* background-color: white; */
  min-width: 240px;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  color: black;
  border: 2px solid #ff5735;
  background-color: #ffe9e9;
  line-height: 20px;
}

.corner-icon {
  display: flex;
  width: 60px;
  justify-content: flex-end;
}

.corner-icon img {
  cursor: pointer;
  padding-left: 5px;
}

.recipe-summary-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.recipe-first-row {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.recipe-summary-info-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  flex-direction: row;
}

.recipe-summary-info-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  width: 74px;
}

.recipe-summary-info-container img {
  height: 30px;
}

.ingregient-info-label {
  padding-top: 5px;
}

.recipe-second-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0 10px 10px 10px;
}

.recipe-third-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 10px;
}

.recipe-ingredients-container {
  padding: 0 10px 10px 10px;
  max-width: 500px;
}

.recipe-ingredients-header {
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px;
  text-decoration: underline;
}

.recipe-ingredients {
  font-size: 12px;
}

.recipe-ingredients > div {
  padding: 5px 0;
  display: flex;
}

.recipe-ingredient-container > div:nth-child(1) {
  min-width: 20px;
}

.ingredient-circle {
  height: 10px;
  width: 10px;
  background-color: #57d08a;
  border-radius: 50%;
  display: inline-block;
}

.recipe-method-header {
  font-weight: bold;
  padding-bottom: 10px;
  text-decoration: underline;
}

.recipe-method-steps {
  font-size: 12px;
}

.recipe-method-steps > div {
  padding: 5px 0;
  display: flex;
}

.recipe-method-step-number {
  font-weight: bold;
}

.recipe-img-container {
  width: 100%;
  position: relative;
}

.recipe-details-header-container {
  text-align: center;
  padding: 14px 0;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 7px 7px 0 0;
  color: white;
  /* box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); */
  /* background-image: linear-gradient(to bottom, rgba(255,0,0,1),rgba(255,0,0,0)); */
}

.add-to-my-meals-container {
  position: absolute;
  right: 8px;
  bottom: 12px;
}

.add-to-my-meals-container button {
  font-size: 12px;
  color: #273235;
  background-color: #fbffc2;
  border-radius: 58px;
  font-weight: bold;
  border: 2px solid #F5FF5E;
}

.add-to-my-meals-container button:hover {
  background-color: #F5FF5E;
  color: #283235;
}

.recipe-img-summary {
  height: 200px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.recipe-img-details {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 7px 7px 0 0;
  box-shadow: 0 3px 4px -2px gray;
}

.try-again-container {
  padding: 10px;
}

.recipe-name {
  font-size: 20px;
  font-weight: bold;
  padding: 0 10px;
  text-transform: uppercase;
}

.recipe-header {
  display: flex;
  justify-content: space-around;
}

.recipe-header div {
  padding: 10px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recipe-description {
  display: flex;
  justify-content: space-around;
}

.reveal-button-continer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  margin: 5px 0 15px 0;
}

.reveal-style {
  color: black;
  font-weight: bold;
  padding: 20px;
  margin: 0 5px;
  border-radius: 5px;
  border: 2px solid #1efc89;
  background-color: #c4ffe0;
  cursor: pointer;
}

.reveal-style:hover {
  background-color: #1efc89;
}

.wobble-hor-bottom {
	-webkit-animation: wobble-hor-bottom 0.8s both;
          animation: wobble-hor-bottom 0.8s both;
  /* -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2; */
}

 @-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}


@media only screen and (max-width: 600px) {

  body {
    font-size: 66%;
  }

  .start-again-button {
    padding: 6px;
  }

  .buttons-spacer-top {
    height: 0;
  }

  .buttons-spacer-bottom {
    height: 52px;
  }

  .selection-question {
    font-size: 16px;
  }

  .option-name {
    font-size: 12px;
    padding-top: 10px;
  }

  .selections-main-container {
    padding-top: 10px;
  }

  .ingredients-container > div {
    padding: 4px;
    width: 58px;
    text-align: center;
  }

  .ingredients-container img {
    height: 40px;
  }

  .selections-header {
    font-size: 16px;
  }

  .selection-name {
    font-size: 10px;
  }

  .view-full-recipe-button {
    padding: 8px;
    width: 100%;
  }

  .recipe-summary-main-container {
    margin: 10px 10px 0 10px;
  }

  .recipe-details-main-container {
    margin: 10px 10px 0 10px;
  }

  .recipe-name {
    text-align: center;
    font-size: 16px;
    /* width: 180px; */
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }

  .recipe-img-summary {
    /* height: 150px; */
    width: 100%;
    object-fit: cover;
  }

  .recipe-img-details {
    height: 240px;
  }

  .recipe-summary-info-container > div {
    width: 54px;
  }

  .recipe-ingredients-header, .recipe-method-header {
    font-size: 14px;
  }

  .recipe-ingredients, .recipe-method-steps {
    font-size: 12px;
  }

  .loader-container {
    font-size: 16px;
    margin-bottom: 0;
  }

  .corner-icon img {
    height: 20px;
  }

  .error-container {
    max-width: 228px;
    font-size: 12px;
  }

  .add-to-my-meals-container {
    font-size: 10px;
    right: 4px;
    bottom: 6px;
  }

  .add-to-my-meals-container button {
    font-size: 10px;
  }

  .search-option-input {
    font-size: 12px;
    width: 300px;
  }
}