#root {
  /* display: flex;
  flex-direction: column; */
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  background-color: #ffffffd4;
}

.dark-mode {
  background-color: #000000d4;
  color: white;
}

.app-header {
  width: 100%;
  background-color: #50FF9A;
  border-top: 3px solid #50FF9A;
  border-bottom: 3px solid #1efc89;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header div {
  padding: 5px 20px;
}

.auth-container {
  flex: 1 1;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.app-footer {
  height: 40px;
  width: 100%;
  background-color: #50FF9A;
  border-bottom: 3px solid #50FF9A;
  border-top: 3px solid #1efc89;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}


.app-footer > div:nth-child(1) {
  padding-left: 10px;
}

.app-footer-left-container div {
  padding-right: 10px;
}

.app-footer-left-container {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.app-footer-right-container {
  display: flex;
  align-items: center;
}

.app-footer > div:nth-child(2) {
  padding-right: 10px;
}

.left-logo a {
  font-family: 'Lily Script One', cursive;
  font-size: 40px;
}

.app-header a, .app-footer a {
  text-decoration: none;
  color: #273235;
  cursor: pointer;
}

.app-header a:hover, .app-footer a:hover {
  color: #6f6f6f;
}

.app-header a:active, .app-header a:visited, .app-footer a:active, .app-footer a:visited {
  color: #273235;
}

.login-buttons-container {
  display: flex;
  font-weight: bold;
}

.login-buttons-container a {
  padding-left: 10px;
  text-decoration: none;
}

.first-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
}

.logo-description-container {
  padding-bottom: 20px;
}

.big-logo-container {
  font-family: 'Lily Script One', cursive;
  font-size: 70px;
  text-align: center;
}

.site-description-container {
  text-align: center;
  font-size: 16px;
}

.first-choice-question {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.first-choice-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.first-choice-buttons button {
  margin: 5px;
  padding: 10px;
  width: 150px;
}

.let-me-choose {
  font-weight: bold;
  background-color: #c1f3ff;
  color: black; 
  border: 2px solid #00CEFF;
}

.let-me-choose:hover {
  background-color: #00CEFF;
  color: white;
}

.surprise-me {
  font-weight: bold;
  background-color: #e3c0e7;
  color: black; 
  border: 2px solid #CA54D8;
}

.surprise-me:hover {
  background-color: #CA54D8;
  color: white;
}

.cookies-consent {
  /* position: absolute;
  bottom: 46px;
  width: 100%; */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #00CEFF;
  background: #c1f3ff;
  text-align: center;
  font-size: 14px;
}

.cookies-consent div {
  margin: 10px;
}

.cookies-consent button {
  background-color: #e3c0e7;
  border: 1px solid #CA54D8;
  padding: 5px 10px;
  margin: 10px 0;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {

  .app-footer {
    height: 52px;
  }
  
  .app-footer-left-container {
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    font-size: 10px;
  }
  
  .left-logo a {
    font-size: 26px;
  }

  .big-logo-container {
    font-size: 50px;
  }

  button {
    font-size: 14px;
  }

  .site-description-container {
    font-size: 12px;
  }

  .cookies-consent {
    flex-direction: column;
  }

  .cookies-consent div {
    margin: 10px 10px 0 10px;
  }
}